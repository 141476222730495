import { ChainId } from "@services/providers/types";
import { API_READ_KEY } from "../../../keys/keys";

export const environment = {
    development: true,
    staging: false,
    production: false,
    api_host: "https://hypergpt-api-stage.uprotel.com",
    api_key: API_READ_KEY,
    activeChainId: ChainId.BSC_TESTNET,
    referralBonusMinAmountWithdraw: 1,
    idp_show: "google"
};
